import React from 'react';
import horizontalLogo from '../assets/images/logo_horizontal.png';

const EULA: React.FC = () => {
    return (
        <div className='flex flex-col items-center bg-[#141414] text-white p-5 md:p-10'>
            <div className='h-12 md:h-24' />
            <h1 className='text-usc-gold text-[36px] md:text-[48px] font-bold text-center'>
                End User License Agreement (EULA)
            </h1>
            <div className='h-6 md:h-12' />
            <div className='max-w-4xl text-[18px] md:text-[20px] space-y-6'>
                <p>
                    This End User License Agreement (EULA) sets forth the terms and conditions governing the use of the mobile application branded as "Fallasana," developed and operated by Langugo LLC (hereinafter referred to as the "Application"). By downloading, installing, or using the Application, you acknowledge that you have read, understood, and agree to be bound by all provisions of this EULA. If you do not agree to these terms, please discontinue the use of the Application immediately and uninstall it from your device.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>1. Grant of License</h2>
                <p>
                    Langugo LLC grants you a personal, non-commercial, limited, revocable, and non-exclusive license to use the Application under this EULA. This license is valid only if you use the Application in compliance with the terms specified herein.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>2. License Limitations</h2>
                <p>
                    When using the Application, you agree to adhere to the following limitations:
                </p>
                <ul className='list-disc list-inside'>
                    <li>You may not resell, rent, lease, lend, or transfer the Application to any third party.</li>
                    <li>You may not decompile, reverse engineer, or attempt to derive the source code of the Application, or create derivative works based on it.</li>
                    <li>You may not use the Application for illegal purposes or in any manner that violates this EULA.</li>
                    <li>You may not attempt to modify the content or functionality of the Application.</li>
                </ul>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>3. Non-Collection of Personal Information</h2>
                <p>
                    The Application does not collect personal information such as email addresses, phone numbers, or social media account details from users. Users may use the Application entirely anonymously. However, for the purpose of enhancing the in-app experience, anonymous demographic information (such as name, date of birth, birth time, birth city, education level, marital status, financial status) may be collected without linking it to user identities.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>4. Free and Premium Memberships</h2>
                <p>
                    The Application provides users with one free diamond daily, which can be used to obtain one fortune reading. This free diamond entitlement may be modified or withdrawn by Langugo LLC at any time.
                </p>
                <p>
                    Weekly, monthly, and annual Premium membership packages are available in the Application. Premium members receive ten free diamonds daily. These diamonds can only be used within the respective day and do not roll over to the next day.
                </p>
                <p>
                    When the diamonds provided under the Premium membership are depleted, users may purchase additional diamond packs.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>5. Third-Party Content</h2>
                <p>
                    The Application may contain advertisements and content provided by third parties. Langugo LLC does not accept any responsibility for the accuracy, validity, or legality of such content. Users may earn free diamonds by viewing third-party advertisements, but no personal information is collected during this process.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>6. Updates and Modifications</h2>
                <p>
                    Langugo LLC may periodically make updates or changes to enhance the functionality of the Application. These updates may be automatically downloaded and installed on your device. If you prefer not to accept updates, you may need to discontinue using the Application.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>7. Termination</h2>
                <p>
                    This EULA remains effective as long as users continue to use the Application. However, Langugo LLC reserves the right to suspend or permanently terminate user access without notice if users violate this EULA.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>8. Disclaimer of Liability</h2>
                <p>
                    The Application is provided solely for entertainment purposes. The fortune readings provided within the Application are generated automatically by artificial intelligence and should not be considered as professional advice in any form. Langugo LLC shall not be held responsible for the accuracy, reliability, or consequences of decisions made based on these readings.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>9. Limited Warranty</h2>
                <p>
                    The Application is provided on an "as is" and "as available" basis. Langugo LLC does not warrant that the Application will operate uninterrupted, error-free, or securely. Users use the Application at their own risk and are responsible for any damages arising from the use of the Application.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>10. Governing Law and Jurisdiction</h2>
                <p>
                    This EULA is governed by and interpreted in accordance with the laws of the State of Wyoming, United States. In the event of any legal dispute arising from this EULA, only the courts of the State of Wyoming shall have jurisdiction.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>11. Contact Information</h2>
                <p>
                    For any questions, complaints, or feedback regarding this EULA, you may contact Langugo LLC via the support email address provided below:
                </p>
                <p>
                    Email Address for all inquiries: <a href="mailto:info@fallasana.com" className='text-usc-gold underline'>info@fallasana.com</a>
                </p>
                <p>Last Updated: September 1, 2024</p>
            </div>
            <div className='h-8 md:h-16' />
        </div>
    );
}

export default EULA;