import React from 'react';
import horizontalLogo from '../assets/images/logo_horizontal.png';

const TermsOfUse: React.FC = () => {
    return (
        <div className='flex flex-col items-center bg-[#141414] text-white p-5 md:p-10'>
            <div className='h-12 md:h-24' />
            <h1 className='text-usc-gold text-[36px] md:text-[48px] font-bold text-center'>
                Terms of Use
            </h1>
            <div className='h-6 md:h-12' />
            <div className='max-w-4xl text-[18px] md:text-[20px] space-y-6'>
                <p>
                    These Terms of Use are prepared to detail the conditions and rules applicable to the use of the mobile application branded as "Fallasana" developed and operated by Langugo LLC. Fallasana offers users AI-supported interpretations in various fortune-telling categories (Coffee Reading, Tarot Reading, Card Reading, Face Reading, Palm Reading, Horoscopes, Dream Interpretation, Astrology, Numerology, Lucky Numbers). By accepting these Terms of Use, you affirm your right to use the Fallasana application and commit to complying with these conditions. If you do not accept these terms, please discontinue using the application immediately.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>1. Collection and Use of Personal Information</h2>
                <p>
                    The Fallasana application is committed to protecting users' identities and maintaining their privacy; therefore, it does not collect any email addresses, phone numbers, social media accounts, or any other personal information. This allows users to use the application completely anonymously without having to disclose their identities.
                </p>
                <p>
                    To enhance functionality and provide more personalized and targeted interpretations, demographic information such as name, date of birth, birth time, birthplace, education level, marital status, and financial status may be requested anonymously. This information will only be used to improve the accuracy of interpretations without being linked to user identities.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>2. Free and Premium Membership Options</h2>
                <p>
                    The Fallasana application provides each user with one free diamond daily, allowing them to obtain a reading in any category. This free diamond entitlement is provided with the right for Fallasana management to change, withdraw, or replace it with new campaigns without prior notice.
                </p>
                <p>
                    Fallasana offers users various Premium membership options for weekly, monthly, and annual durations. Premium membership allows users to access a wider range of features in the application and provides a daily allowance of ten free diamonds. However, these diamonds must be used within the respective day and do not roll over to the next day, meaning there is no option to accumulate them.
                </p>
                <p>
                    When the diamonds provided under Premium membership are depleted, users can continue to obtain readings by purchasing additional diamond packs individually.
                </p>
                <p>
                    Users have the opportunity to earn additional free diamonds by sharing the Fallasana application with friends. Additionally, users can earn free diamonds by watching in-app advertisements.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>3. Purpose and Use of the Application</h2>
                <p>
                    The Fallasana application serves solely as a digital platform for entertainment and enjoyment. The fortune interpretations provided within the application are generated entirely by artificial intelligence algorithms and are not intended to reflect real-life events, situations, or personal decisions.
                </p>
                <p>
                    Therefore, the interpretations or predictions offered by the Fallasana application should not be regarded as professional consulting services, and users should not rely on these interpretations when making legal, medical, financial, or personal decisions. Langugo LLC cannot be held responsible for any consequences arising from these interpretations.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>4. Disclaimer</h2>
                <p>
                    The Fallasana application explicitly states that the services provided to users are for entertainment purposes only. The fortune interpretations included in the application are generated by artificial intelligence and are independent of users' personal information and preferences. These interpretations do not provide any professional advice, guidance, or guarantees and should only be considered for entertainment and enjoyment.
                </p>
                <p>
                    Langugo LLC accepts no responsibility for the accuracy, validity, or reliability of the fortune interpretations provided in the Fallasana application. Users acknowledge that these interpretations are solely for entertainment, and they are fully responsible for any decisions made based on these AI-generated interpretations.
                </p>
                <p>
                    The fortune interpretations in the Fallasana application do not contain definitive predictions or guarantees regarding users' futures. Therefore, Langugo LLC cannot be held responsible for any damages resulting from actions taken or decisions made by users based on these interpretations.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>5. Third-Party Advertisements and Rewards</h2>
                <p>
                    The Fallasana application may include third-party advertisements, which may be offered to increase users' chances of earning free diamonds. Users can earn additional free diamonds by watching these advertisements. However, Langugo LLC cannot be held responsible for the content of third-party advertisements or any issues that may arise from them. We have no control over the content of these advertisements, and users should watch them at their own discretion.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>6. Changes and Updates to the Terms of Use</h2>
                <p>
                    Langugo LLC reserves the right to change or update the Terms of Use for the Fallasana application at any time. Any changes or updates will be communicated to users via in-app notifications or email. Continuing to use the application after changes means you accept the updated terms. If you do not accept these terms, you must discontinue using the application immediately.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>7. Termination of the Application and User Accounts</h2>
                <p>
                    Fallasana reserves the right to suspend or permanently close the accounts of users who violate the Terms of Use or misuse the application without any warning. In such cases, any remaining diamonds or membership periods will not be refunded.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>8. Governing Law and Jurisdiction</h2>
                <p>
                    These Terms of Use are governed by and interpreted in accordance with the laws of the State of Wyoming, United States. In the event of any disputes arising from the Fallasana application, only the courts of the State of Wyoming shall have jurisdiction. Such legal disputes will be resolved by these courts.
                </p>

                <p>
                    By using the Fallasana application, you affirm that you have carefully read, understood, and accepted all the terms and conditions stated above. If you do not comply with any of these conditions, you must discontinue using the application immediately. If you have any questions or need assistance, please contact us via the support email address provided below.
                </p>
                <p>
                    For any inquiries and suggestions, please use the email address: <a href="mailto:info@fallasana.com" className='text-usc-gold underline'>info@fallasana.com</a>
                </p>
                <p>Last Updated: September 1, 2024</p>
            </div>
            <div className='h-8 md:h-16' />
        </div>
    );
}

export default TermsOfUse;